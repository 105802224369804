@use "variables";

#technologies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  padding-bottom: 300px;
}

.technologiesTitle {
  color: variables.$accent;
  font-weight: 600;
  font-size: 20px;
}

.technologiesHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  p {
    font-size: 20px;
    color: variables.$light;
    font-weight: 300;
  }
}

.technologiesListContainer {
  padding-top: 50px;
  .techInnerContainer {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 40px;
    li {
      border-radius: 2px;
      padding: 20px;
      display: inline;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      img {
        width: 40px;
        height: 40px;
      }
      h6 {
        font-weight: 500;
        color: variables.$light;
      }
    }
  }
}

.techListItemContainer {
  border-radius: 5px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
}
.techListItemContainer:hover {
  background-color: #16284977;
}
